import { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

import { showLoading } from 'services/util'

import { graphql } from '../../api'
import { adminMakePaymentMutation, updateAuctionItemMutation } from '../../graphql/Mutations'
import { adminGetAuctionByCode } from '../auctionsadminutil'
import { sortSpan, handleSort, performSort } from '../auctionsadminutil'

import css from './sass/closedauction.module.scss'
import themeCss from '../../components/sass/theme.module.scss'
import ClosedManageCheckoutRow from './ClosedManageCheckoutRow'
import ConfirmMakePayment from '../ConfirmModal/ConfirmMakePayment'
import ConfirmMarkAsPaid from '../ConfirmModal/ConfirmMarkAsPaid'

export default function ClosedManageCheckout(props) {
  const [itemsPaidCount, setItemsPaidCount] = useState(0)
  const [itemsUnpaidCount, setItemsUnpaidCount] = useState(0)

  const [selectedItems, setSelectedItems] = useState([])
  const [chargeTotal, setChargeTotal] = useState(0)

  //confirm modal variables
  const [modalUse, setModalUse] = useState()
  const [modalData, setModalData] = useState()
  const [showModal, setShowModal] = useState(false)

  //array of truth for all attendees in auction
  const [attendeeItems, setAttendeeItems] = useState([])

  const [sortOptions, setSortOptions] = useState({ key: '', dir: 'asc' })
  const tableFormatter = [
    ['Title', 'Item Name'],
    ['CurrentBid', 'Item Amount Due'],
    ['Paid', 'Paid']
  ]

  //to refresh query on changes
  const queryClient = useQueryClient()

  const {auctionCode, managedAttendee, toAllAttendeePage} = props

  const userId = managedAttendee?.CurrentHighBidder
  const userCrmId = managedAttendee?.CurrentHighBidderInfo?.CrmId

  // load auction data by code, so it will work for deep-linking
  const { data: auctionDetail, error, isLoading } = useQuery({
    queryKey: ["adminAuctionDetail", auctionCode],
    queryFn: async () => {
      return await adminGetAuctionByCode(auctionCode)
    },
    staleTime: 0
  })
  console.log('managed attendee', managedAttendee)

  const {data: updateItemResponse, updateItemError, isPending, mutate: updateItem } = useMutation({
    mutationFn: (input) => {
      return graphql({query: updateAuctionItemMutation, variables: {input: input} })
    },
  })

  const {data: adminMakePaymentResponse, error: adminMakePaymentError, isPending: adminMakePaymentPending, mutate: adminCharge } = useMutation({
    mutationFn: (input) => {
      return graphql({query: adminMakePaymentMutation, variables: {...input} })
    },
  })

  //use effect for query results
  useEffect(() => {
    showLoading(isLoading)

    if (error || (auctionDetail && auctionDetail.error)) {
      console.log('Error!', error || auctionDetail.error)
    }

  }, [auctionDetail, error, isLoading])

  
  const markAsPaid = async (e) => {

    // check if paymentDetails has anything, if it does - cannot mark as paid
    // showLoading(true)

    const data = {
      itemsMarkedPaid: selectedItems,
      totalMarkedPaid: chargeTotal
    }

    setModalData(data)
    setModalUse('markAsPaid')
    setShowModal(true)
  }

  const chargeAmountDueAction = () => {

    const data = {
      auctionId: auctionDetail.AuctionId,
      itemsCharged: selectedItems,
      totalCharged: chargeTotal,
      endUserId: userId,
      endUserCrmId: userCrmId,
    }

    setModalData(data)
    setModalUse('confirmCharges')
    setShowModal(true)
  }

  //for update item
  useEffect(() => {
    showLoading(isPending)

    if (updateItemError) {
      console.log("Item not updated: ", updateItemError)
      console.log(updateItemError.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (updateItemResponse) {
      console.log(updateItemResponse)
      console.log('Item updated: ', updateItemResponse.data.updateAuctionItem.ItemId)
      queryClient.invalidateQueries(['adminAuctionDetail'])
      setShowModal(false)
      setSelectedItems([])
    }
  }, [updateItemResponse, updateItemError, isPending, queryClient])

  //for make admin payment
  useEffect(() => {

    showLoading(adminMakePaymentPending)

    if (adminMakePaymentError) {
      console.log("Item not updated: ", adminMakePaymentError)
      console.log(adminMakePaymentError.errors[0].message) // errors from Appsync take this format, there may be other formats depending on where the operation fails
    }
    else if (adminMakePaymentResponse) {
      console.log(adminMakePaymentResponse)
      console.log('Item updated: ', adminMakePaymentResponse.data)
      queryClient.invalidateQueries(['adminAuctionDetail'])
      setShowModal(false)
      setSelectedItems([])
    }

  }, [adminMakePaymentResponse, adminMakePaymentError, adminMakePaymentPending, queryClient])


  //for loading - check all pending and loadings inside the component to override
  useEffect(() => {

    if (adminMakePaymentPending || isLoading || isPending) showLoading(true)
    else showLoading(false)

  }, [adminMakePaymentPending, isLoading, isPending])

  // use effect to take adminAuctionDetail and reformat the data for attendee display, assigning it to attendeeItems for mapping in JSX 
  // maybe could reformat into adminAuctionDetail if we don't need all of the details the query returns
  useEffect(() => {

    console.log(auctionDetail)
    if (auctionDetail) {
      const adminItems = auctionDetail?.Items
      
      const userItems = adminItems.filter((item) => item.CurrentHighBidder === userId)

      const itemsPaid = userItems.reduce((acc, item) => {
        if (item.Paid) return acc + 1
        else return acc
      }, 0)

      setItemsPaidCount(itemsPaid)
      setItemsUnpaidCount(userItems.length - itemsPaid)
      setAttendeeItems(userItems)

    }

  }, [auctionDetail, userId])

  useEffect(() => {

    const calcTotal = attendeeItems.reduce((sum, item) => {
      if (selectedItems.includes(item.ItemId)) sum += item.CurrentBid
      return sum
    }, 0)

    setChargeTotal(calcTotal)

  }, [selectedItems, attendeeItems, setChargeTotal])

  const toggleCheckbox = (userId) => {
    const userIndex = selectedItems.indexOf(userId)
    const returnArr = [...selectedItems]

    if (userIndex > -1) returnArr.splice(userIndex, 1)
    else returnArr.push(userId)

    setSelectedItems(returnArr)
  }

  const selectAllItems = (checked) => {
    let returnArr = [];

    if (checked) {
      returnArr = attendeeItems.map((attendee) => attendee.ItemId)
    } else returnArr = []

    console.log('return Arr', returnArr)

    setSelectedItems(returnArr)

  }

  const countItems = () => {
    return selectedItems.reduce((count, value) => count + (value ? 1 : 0), 0)
  }

  if (!auctionDetail || !attendeeItems) return (<></>)
  
  const sortedItems = performSort(attendeeItems, sortOptions)

  return (
    <div className={css['closed-auction-container']}>

      {(showModal && modalUse === 'confirmCharges') && <ConfirmMakePayment setShowConfirmModal={setShowModal} data={modalData} adminCharge={adminCharge} /> }
      {(showModal && modalUse === 'markAsPaid') && <ConfirmMarkAsPaid setShowConfirmModal={setShowModal} data={modalData} updateItem={updateItem} /> }

      <div className={css['closed-auction-attendee-heading']}>
        <h2>ATTENDEE NAME: {attendeeItems[0]?.CurrentHighBidderInfo?.FirstName} {attendeeItems[0]?.CurrentHighBidderInfo?.LastName}</h2>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']}`} onClick={() => toAllAttendeePage()}>
          Back to All Attendees
        </button>
      </div>

      <div className={css['closed-auction-summary']}>
        <div>
          <p>{itemsUnpaidCount} ITEMS WITH BALANCE DUE</p>
          <p>{itemsPaidCount} ITEMS WITH FULLY PAID BALANCE</p>
        </div>
      </div>

      <div className={css['closed-auction-charge-selected']}>
        <p>
          {countItems()} ITEMS SELECTED
        </p>
        <button disabled={selectedItems.length < 1}
          onClick={() => chargeAmountDueAction()} 
          className={`${themeCss['button']} ${themeCss['FilledBlue']}`}>
          Charge Amount Due
        </button>
        {/* <button className={`${themeCss['button']} ${themeCss['FilledWhite']}`}>
          Allow Alt. Payment
        </button> */}
        <button disabled={selectedItems.length !== 1}
          onClick={() => markAsPaid()}
          className={`${themeCss['button']} ${themeCss['FilledWhite']}`}>
          Mark As Paid
        </button>
        <p className={css['closed-auction-charge-note']}>
          Currently 'Mark as Paid' done one item at a time.
        </p>
      </div>

      <p className={themeCss['boldGreen']}>Items in bold green are above Fair Market:</p>

      <div className={css['closed-auction-table']}>
        <table>
          <thead>
            <tr className={css['closed-auction-table-header']}>
              <th>
                <input type='checkbox' onChange={(e) => selectAllItems(e.target.checked)}
                  checked={selectedItems.length === attendeeItems.length}
                />
              </th>
              {tableFormatter.map((ele) => {
              return (
                <th onClick={() => handleSort(ele[0], sortOptions, setSortOptions)} key={ele[1]}>
                  {ele[1]} {sortSpan(ele[0], sortOptions)}</th>
                )})}
              {/* <th>Item Name</th>
              <th>Item Amount Due</th>
              <th>Paid</th> */}
            </tr>
          </thead>
          <tbody>
            {sortedItems && sortedItems.map((item) => {
            return <ClosedManageCheckoutRow key={item.ItemId} 
                    selectedItems={selectedItems} toggleCheckbox={toggleCheckbox}
                    item={item} />
            })}
          </tbody>
        </table>
      </div>
      
    </div>
  )
}
import { NewGiftStyles } from './shared/SharedStyles'

const TaxDocumentation = () => {

  return (
    <NewGiftStyles>
      <ul>
        <li>
          <a href="https://www.finance.ucla.edu/tax-records/tax-services" target="_blank"
             rel="noreferrer">University’s Tax Status</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/oiqjj1u2bs1xkbq523v6ce1sdnwjxkkl" target="_blank"
             rel="noreferrer">UCLA Foundation California Determination Letter</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/s5wmgyqqwnswlgierkncydq3876kicx4" target="_blank"
             rel="noreferrer">UCLA Foundation Federal Tax Exemption Status</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/vsl05cupp4z8j02frj2duphk3ru2hagr" target="_blank"
             rel="noreferrer">UCLA Foundation W-9 form</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/wl5alev8o5mid6vej22uuy142tfrr0qw" target="_blank"
             rel="noreferrer">UC Regents Federal Tax Exemption Status Letter</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/ivvwg57w04gtog3al3sxw78t3j0epwdl" target="_blank"
             rel="noreferrer">University of California Certificate of Incorporation</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/fz9g4ic7eakbwfrutyf061mm8h0fdkxc" target="_blank"
             rel="noreferrer">UCLA W-9 form</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/nzkol8qh57xxgtrqal7ihkfhrrsmf93t" target="_blank"
             rel="noreferrer">Tax-Efficient Giving for Hong Kong Donors</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/45igp9bx2zp94fb2rbdgh5bp5v2lh78j" target="_blank"
             rel="noreferrer">UCLA Foundation Form 990 For Public Distribution</a>
        </li>
        <li>
          <a href="https://ucla.box.com/s/pe2ukr5i7vapih9gq1wker9ubnseawov" target="_blank"
             rel="noreferrer">Most Recent UCLA Foundation Audited Financial Statement</a>
        </li>
      </ul>
    </NewGiftStyles>
  )
}

export default TaxDocumentation

import { useState } from 'react'

import css from './sass/liveauction.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function LiveItems( {auctionDetail, item} ) {
  const [showInfo, setShowInfo] = useState(false)

  let itemBids = []
  
  if (auctionDetail?.Bids?.length > 0) {
    //individual item bids sorted with most recent on top
    itemBids = auctionDetail.Bids.filter(bid=> bid.ItemId === item.ItemId)
    .sort((a,b) => a.Timestamp < b.Timestamp ? 1 : -1)
  }

  const click = () => {
    let current = showInfo
    setShowInfo(!current)
  }

  return (
    <div>
      <button className={css['admin-info-modal-link']} onClick={()=>click()} >{item.BidCount}</button>
      
      {showInfo && (<>
        <div className={themeCss['modal-background']} onClick={()=>click()}> </div>
        <div className={themeCss['modal-card']}>
          <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`} onClick={()=>click()} >Close Modal</button>
          
          <h4 className={css['admin-info-modal-header']}>Bid History for {item.Title}</h4>
          <table className={css['admin-infobids-table']}>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Bid Amount</th>
                <th>Bid Time</th>
              </tr>
            </thead>
            <tbody>
            {itemBids && itemBids.map((bid) => {
                return (
                  <tr key={bid.Timestamp}>
                    <td>{bid.FullName}</td>
                    <td>${bid.BidAmount}</td>
                    <td>{new Date(bid.Timestamp).toLocaleString()}</td>
                    
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </>)}
    </div>    
  )
}
import themeCss from '../../components/sass/theme.module.scss'
import css from './sass/closedauction.module.scss'

export default function ClosedAuctionAttendeeItemRow( {item, selectedItems, toggleCheckbox} ) {

  return (
    <tr className={css['closed-auction-table-data-row']}>
      <td>
        <input type='checkbox'
          checked={selectedItems.indexOf(item.ItemId) > -1}
          onChange={() => toggleCheckbox(item.ItemId)} 
          />
      </td>
      <td>{item.Title}</td>
      {item.Paid ? 
        <td>$0</td> : 
          <td className={item.CurrentBid > item.Value ? themeCss['boldGreen']: ''}>${item.CurrentBid}</td>}
      {item.Paid ?
        <td className={item.CurrentBid > item.Value ? themeCss['boldGreen'] : ''}>YES</td> : 
          <td>NO</td>}
      {/* <td>
        <div className={css['closed-auction-item-actions']}>
            <p onClick={()=> console.log('hi')}>
              Charge Full / Partial Amount
            </p>
            <p>
              Modify Amount Due
            </p>
            <p>
              Mark as Paid
            </p>

        </div>
      </td> */}
    </tr>
  )
}
import { initializeDate } from 'services/util'
import { DATE_MONTH } from 'common/forms/data/BioData'

export const adminErrorHandler = (err, matchErrMsg) => {
    if (err && err.key === 'CALL_FAILED') {
        return 'Call failed. Possibly your IP address is not whitelisted for admin use.';
    }

    // ...

    return matchErrMsg;
}

export const formatDateMonth=(rawDate)=>{

  const date = initializeDate( rawDate )

  let month =  DATE_MONTH.month[date.getMonth()]
  // month = month.length > 1 ? month : '0' + month
  let day = date.getDate().toString()
  // day = day.length > 1 ? day : '0' + day

  return (month + ' ' + day + ', ' +  date.getFullYear())

}
export const formatDate = (rawDate, leadingZeros) => {

  const addLeadingZeros = dateStr => {
    return leadingZeros && dateStr.length === 1 ? '0' + dateStr : dateStr
  }

  if (!rawDate) return '';

  const date = new Date(rawDate)
  let month = addLeadingZeros((1 + date.getMonth()).toString())
  let day = addLeadingZeros(date.getDate().toString())

  return (month + '/' + day + '/' +  date.getFullYear())
}

export const formatPhone = (val) => {
  if (!val) return '';

  if (val && val.indexOf('@') === -1 && val.indexOf('+') === 0)
    return val.substring(1,2) + '-' + val.substring(2,5) + '-' + val.substring(5,8) + '-' + val.substring(8,12);
  else if (val && val.indexOf('@') === -1 && /\d{10}/.test(val))
    return val.substring(0,3) + '-' + val.substring(3,6) + '-' + val.substring(6,10)
  else
    return val;
}
import adminCss from '../sass/adminpage.module.scss'
import ClosedItemDisplay from './ClosedItemDisplay'


export default function ClosedItems(props) {

  return (
    <div className={adminCss['admin-auction-page-tab-container']}>
      <h3 className={adminCss['admin-auction-page-tab-header']}>
        Auction Items
      </h3>

      <ClosedItemDisplay selectedAuction={props.auction} />
      
    </div>
  )
}
import css from './sass/liveauction.module.scss'

export default function LiveAuctionRegistrationRow( {registration, needsCheckin} ) {
  // make sure to update copyAttendeesToClipboard in LiveAttendeeView if these columns change
  return (
    <tr className={css['live-auction-table-data-row']}>
      <td>{registration.FullName}</td>
      {needsCheckin && <td>{registration.CheckStr}</td>}
      <td>{registration.EmailStr}</td>
      <td>{registration.CrmId}</td>
      <td>{registration.PhoneStr}</td>
      <td>{registration.RegDate}</td>
    </tr>
  )
}
import { useState, useEffect } from 'react'

import { sortSpan, handleSort, performSort } from './auctionsadminutil'

import css from './sass/adminitem.module.scss'
import adminCss from './sass/adminpage.module.scss'
import themeCss from '../components/sass/theme.module.scss'
import ItemDisplayRow from './ItemDisplayRow'

import CreateItem from './CreateItem'
import ItemEdit from './ItemEdit'

export default function ItemDisplay( {selectedAuction, refreshAdminAuctionQuery } ) {

  const [showItemModal, setShowItemModal] = useState(false)
  const [editItem, setEditItem] = useState()
  const [showEditModal, setShowEditModal] = useState(false)
  
  const [displayItems, setDisplayItems] = useState([])
  const [sortOptions, setSortOptions] = useState({ key: '', dir: 'asc' })

  const tableFormatter = [
    ['Title', 'Item Title'],
    ['Value', 'Fair Market Value'],
    ['StartingBid', 'Starting Bid'],
    ['MinRaise', 'Min. Raise'],
    ['ItemCode', 'Item #'],
    ['Donor', 'Donated By']
  ]

  const showEditItemModal = () => {
    let newValue = !showEditModal
    setShowEditModal(newValue)
  }

  const updateEditItemId = (id) => {
    const updatedItem = selectedAuction.Items.find(ele => ele.ItemId === id)
    setEditItem(updatedItem)
    showEditItemModal()
  }

  const showCreateItemModal = () => {
    let newValue = !showItemModal
    setShowItemModal(newValue)
  }

  useEffect(() => {
    if (selectedAuction.Items) {
      const output = selectedAuction.Items.map((item) => {
        return {
          ...item,
          ItemCode: parseInt(item.ItemCode)
        }
      })
      setDisplayItems(output)
    }

  }, [selectedAuction])

  const sortedItems = performSort(displayItems, sortOptions)
  
  // maybe provide a link for if no auction is loaded to reroute to load auctions - 404 something
  if (!selectedAuction) return (<></>)
  
  return (
    <>
      <div className={css['admin-items-container']}>
        <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`} onClick={()=> showCreateItemModal()} >
          Add Item 
        </button>

        {selectedAuction.Items.length !== 0 && 
        <table className={css['admin-item-table']}>
          <thead>
            <tr className={css['admin-item-row']}>
              <th></th>
              {tableFormatter.map((ele) => {
              return (
                <th onClick={() => handleSort(ele[0], sortOptions, setSortOptions)} key={ele[1]}>
                  {ele[1]} {sortSpan(ele[0], sortOptions)}</th>
                )})}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className={css['admin-item-body']}>
            {sortedItems && sortedItems.map((item) => {
              return (
                <ItemDisplayRow key={item.ItemId} auction={selectedAuction} item={item}
                  refreshAdminAuctionQuery={refreshAdminAuctionQuery}
                  updateEditItemId={updateEditItemId} />
                )
              })}
          </tbody>
        </table>}

        {selectedAuction.Items.length === 0 && <p>No Items, add some!</p>}
        
      </div>
      {showItemModal && 
        <>
          <div className={themeCss['modal-background']} onClick={()=>showCreateItemModal()}></div>
          <div className={adminCss['auction-modal']}>
            <button className={`${themeCss['button']} ${themeCss['FilledYellow']} ${themeCss['centered-button']}`} onClick={()=>showCreateItemModal()} >Close Modal</button>
            <CreateItem refreshAdminAuctionQuery={refreshAdminAuctionQuery} auction={selectedAuction}/>
          </div>
        </>
      }
      {showEditModal && 
        <>
          <div className={themeCss['modal-background']} onClick={()=>showEditItemModal()}></div>
          <div className={adminCss['auction-modal']}>
            <button className={`${themeCss['button']} ${themeCss['FilledYellow']} ${themeCss['centered-button']}`} onClick={()=>showEditItemModal()} >Close Modal</button>
            <ItemEdit item={editItem} auction={selectedAuction} refreshAdminAuctionQuery={refreshAdminAuctionQuery}/>
          </div>
        </>
      }
    </>
  )
}
import { useState } from 'react'

import css from './sass/liveauction.module.scss'
import themeCss from '../../components/sass/theme.module.scss'

export default function LiveUserBids( {auctionDetail, user} ) {

  const {UserId: userId, FullName: fullName, numberOfBids} = user

  const [showInfo, setShowInfo] = useState(false)

  const bids = auctionDetail.Bids
  //individual item bids sorted with most recent on top
  const userBids = bids?.filter(bid=> bid.UserId === userId)
  .sort((a,b) => a.Timestamp < b.Timestamp ? 1 : -1)

  const itemMap = {}
  auctionDetail.Items.forEach((item) => {
    itemMap[item.ItemId] = item.Title
  })

  const click = () => {
    let current = showInfo
    setShowInfo(!current)
  }

  return (
    <div>
      <button className={css['admin-info-modal-link']} onClick={()=>click()} >{numberOfBids}</button>
      
      {showInfo && (<>
        <div className={themeCss['modal-background']} onClick={()=>click()}></div>
        <div className={themeCss['modal-card']}>
          <button className={`${themeCss['button']} ${themeCss['FilledBlue']} ${themeCss['centered-button']}`} onClick={()=>click()} >Close Modal</button>
          
          <h4 className={css['admin-info-modal-header']}>Bid History for {fullName}</h4>
          <table className={css['admin-infobids-table']}>
            <thead>
              <tr>
                <th>Item Title</th>
                <th>Bid Amount</th>
                <th>Bid Time</th>
              </tr>
            </thead>
            <tbody>
            {userBids && userBids.map((bid) => {
                return (
                  <tr key={bid.Timestamp}>
                    <td>{itemMap[bid.ItemId]}</td>
                    <td>${bid.BidAmount}</td>
                    <td>{new Date(bid.Timestamp).toLocaleString()}</td>
                    
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </>)}
    </div>    
  )
}
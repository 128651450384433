import { useEffect, useState } from 'react'

import adminCss from './sass/adminpage.module.scss'

export default function PreviewPublishChecklist(props) {

  const [checkMessage, setCheckMessage] = useState('')
  const [checkList, setCheckList] = useState([])
  const [itemStatus, setItemStatus] = useState([false])

  const greenCheck = <p className={adminCss['greencheck']}>✔</p>
  const redX = <p className={adminCss['redx']}>𐄂</p>
  
  
  useEffect(() => {
    const itemsPaidFor = () => {
      if (!props.auctionDetail) return
  
      //filter out unclaimed items, and return only if they are paid off
      const paidCheck = props.auctionDetail.Items.filter(item => item.BidCount > 0).map(item => item.Paid)
      const allTrue = (currentValue) => currentValue === true;
  
      return [paidCheck.every(allTrue)] 
    }
    
    const auctionItemCheck = () => {
  
      if (!props.auctionDetail) return
      const values = Object.values(props.auctionDetail)
      const strValues = values.filter((val) => typeof(val) === 'string')
      const noLength = (value) => { return value.length === 0 }
      // checking to see if any of them are empty, would return true if any are empty
      const hasEmpty = strValues.some(noLength)
      const hasItems = props.auctionDetail?.Items?.length > 0
  
      //return the opposite of hasEmpty to display correct check or X 
      return [!hasEmpty, hasItems]
    }

    if (!props.auctionDetail) return

    if (props.liveStatus === 'ended') {
      setCheckMessage('Auction Complete Checklist:')
      setCheckList(['All items paid for'])
      setItemStatus(itemsPaidFor())
    } else {
      setCheckMessage('Auction Ready Checklist:')
      setCheckList(['Auction Details Complete', 'Auction Has Items'])
      setItemStatus(auctionItemCheck())
    }
    
  }, [props.liveStatus, props.auctionDetail])

  if (!props.auctionDetail) return <></>

  return (
    <div className={adminCss[`widget-checklist-${props.location}`]}>
      <p className={adminCss['widget-checklist-header']}>
        {checkMessage}
      </p>
      {checkList?.map((item, idx) => <li key={`${item}-${idx}`}
                                      className={adminCss[`widget-checklist-${props.location}-item`]}>
                                        <span>{itemStatus[idx] ? greenCheck : redX}</span>
                                          {item}
                                    </li>)}
    </div>
  )
}